import React, { useEffect, useState } from "react";
import { Box, Button, TablePagination, Tooltip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import StickyHeadTable from "../../component/styleTabel";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DataTable from "../../component/tabel/DataTabel";
import ShortingTable from "../../component/tabel/ShortingTabel";
import {
  useGetAllOperations,

  useGetExportExcelOrders,
  
  useGetStatus,
} from "../../component/customHooks/customHook";
import Pagination from "../../component/tabel/pagination";
import CustomButton from "../../component/coustombutton/CoustomButton";
import SortPopupComponent from "../../common/sort/sortPopupComponent";
import ActiveLastBreadcrumb from "../../component/breadcrumb/ActiveLastBreadcrumb";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import RemovePopup from "../../component/removemasterdata/removePopup";
import InfluencerDrawer from "../../component/viewdetails/orderViewDetails";
import OrderViewDetails from "../../component/viewdetails/orderViewDetails";
import FilterPopupComponent from "../../common/filter/filterPopup";
import NoteHistory from "../../component/adminhistory/NoteHistory";
import AdminNotePopup from "../../component/adminnote/AdminNotePopup";
import HistoryIcon from "@mui/icons-material/History";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import { postJsonData, putJsonData } from "../../Services/AxoisInterceptorr";
import VisitorEndpoint from "../../Services/VisitorEndpoint";
import { toast } from "react-toastify";
import { AlignHorizontalLeft } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { setOrderFilter } from "../../store/FilterSlice";
import DateRangeFilter from "../../component/datefilter/DateRangeFilter";
import DeleveryDateRangeFilter from "../../component/datefilter/DeleveryDateRangeFilter";
import RateReviewIcon from "@mui/icons-material/RateReview";
import AddReviewPopup from "../../component/adminnote/AddReviewPopup";
import { SmallButton } from "../../component/coustombutton/SmallButton";
import * as XLSX from "xlsx";
import SimCardDownloadIcon from "@mui/icons-material/SimCardDownload";
import { useUser } from "../../contexts/UserDataStore";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import AddAmPmPopup from "../../component/adminnote/AddAmPmPopup";
import MarqueeText from "../../component/marquee/MarqueeText";
import { useGetAllFiltersQuery, useGetOrdersQuery } from "../../Services/ApiSlice";

const sortArray = [
  {
    sortOrder: "desc",
    sortField: "updatedAt",
    sortName: "Updated Date (Dsc",
  },
  {
    sortOrder: "asc",
    sortField: "orderDate",
    sortName: "Order Date (Asc)",
  },
  {
    sortOrder: "asc",
    sortField: "orderValue",
    sortName: "Order Value: Low to High",
  },
  {
    sortOrder: "desc",
    sortField: "orderValue",
    sortName: "Order Value: High to Low",
  },
];

const OrderComponent = () => {
  const dispatch = useDispatch();
  const filterParam = useSelector((state) => state.filter.orderFilter);
  const { user } = useUser();
  const [currentPage, setCurrentPage] = useState(1);
  const [sortField, setSortField] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const [editState, setEditState] = useState();
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [influencerDrawerOpen, setInfluencerDrawerOpen] = useState(false);
  const [openRemoveModal, setOpenRemoveModal] = useState(false);
  const [openManager, setOpenManager] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [rowDatanew, setrowDatanew] = useState(null);
  const [historyDrawerOpen, setHistoryDrawerOpen] = useState(false);
  const [openModalNote, setOpenModalNote] = useState(false);
  const [openReviewNote, setopenReviewNote] = useState(false);
  const [perPageLimit, setPerPageLimit] = useState(25);
  const [row, setRow] = useState();
  const [dateFilter, setDateFilter] = useState({
    startDate: null,
    endDate: null,
  });
  const [isDataReady, setIsDataReady] = useState(false);
  const [flattenData, setFlattenData] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);  
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);  


  const navigate = useNavigate();
  const columns = [
    { id: "sno", label: "S.No.", minWidth: 30 },
    { id: "orderid", label: "Order Id", minWidth: 200 },
    { id: "camapignname", label: "Campaign", minWidth: 180 },
    { id: "influencername", label: "Marqfluencer", minWidth: 100 },
    { id: "product", label: "Product", minWidth: 50 },
    { id: "amount", label: "Amount", minWidth: 30 },
    { id: "status", label: "Status", minWidth: 80 },
    {
      id: "ordermanager",
      label: "OM",
      columnsTooltip: "Order Manager",
      minWidth: 50,
    },
    {
      id: "reimbursed",
      label: "R?",
      columnsTooltip: "Reimbursed?",
      minWidth: 50,
    },
  ];

  const allstatusExport = useGetExportExcelOrders(
    filterParam,
    sortField,
    sortOrder,
    
  );
  const orderDetailsExcel = allstatusExport?.allstatusExport;

  const getAllOperations = useGetAllOperations();
  const [isgetAllOperations, setGetAllOperations] = useState(getAllOperations);
 
 
  const { data, error,isFetching, isLoading,refetch } = useGetOrdersQuery({
    currentPage,
    perPageLimit,
    sortField,
    sortOrder,
    filterParam,
  },{
    refetchOnMountOrArgChange: true,
    keepUnusedDataFor: 60,
  // pollingInterval: 3000, 
  refetchOnReconnect : true,
   
  // refetchOnFocus  : true,
    // onSuccess: (data) => {
    //   console.log("API hit and data fetched:", data);
    // },
    // onError: (error) => {
    //   console.error("API hit but error occurred:", error);
    // } 
  });
  const { data: allFilddters,isLoading:isLoadingFilter,refetch:filterRefech } = useGetAllFiltersQuery({
    refetchOnMountOrArgChange: false,
    keepUnusedDataFor: 30,
  refetchOnReconnect : true,
  
  });

// console.log(allFilddters)
const totalCount = data && data?.data?.totalCount || 0;


  function capitalizeFirstLetters(name) {
    return name
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase())
      .join("");
  }

  const status = useGetStatus("", "", "Order");
  const realData = Array.isArray(data?.data?.data)
  ? data?.data?.data.map((item) => ({
    id: item._id,
    sno: item.sno,
    brandname: item.brand ? item.brand.brandName : "",
    orderid: item.orderId ? item.orderId : "",
    camapignname: item.campaign ? item.campaign.campaignName : "",
    influencername: item.influencer && item.influencer?.influencerName,
    // product: item.product && item.product?.productName ? "...":null,
    product: item.product ? item.product?.productName : null,
    amount: item.orderValue && item.orderValue,
    status: item?.status && item?.status?.status,
    ordermanager:
      item?.orderManager?.name &&
      capitalizeFirstLetters(item?.orderManager?.name),
    reimbursed: item.reimbursed && item.reimbursed,
    // orderplatform: item.orderPlatform.platform,
  }))
  : [];

  // const statuses = realData.map(item => item.status);

  // let filters=[
  //      {
  //       label: "Order Id",
  //       urlParam: "orderid",
  //       values: uniqueOrderData.map((item) => ({
  //         label: item.name,
  //         value: item.id,
  //       })),
  //     },
  // ]
  useEffect(() => {
    // Check if the object with _id: "null" already exists in the state array
    const existingObject = getAllOperations.find((item) => item._id === "null");
    // If the object doesn't exist, update the state to include it
    if (!existingObject) {
      setGetAllOperations([...getAllOperations,{_id:"null",name:"Not Assigned"}])
    }
  }, [getAllOperations]);
  // let filters = []
  let filters = [

    {
    label: "Marqfluencer",
      urlParam: "influencername",
      values:  allFilddters?.uniqueInfluencers?.map((item) => ({
        label: `${item.influencerName} (${item.mobileNumber})`,
        value: item._id,
      })) || [],
    },
    {
      label: "Product",
      urlParam: "productname",
      values:  allFilddters?.uniqueProducts?.map((item) => ({
        label: item.productName.substring(0, 50),
        value: item._id,
      })) || [],
    },
    {
      label: "Status",
      urlParam: "status",
      values: status.allstatus.map((item) => ({
        label: item.status,
        value: item._id,
      })),
    },
    {
      label: "Campaign Name",
      urlParam: "campaignname",
      values:  allFilddters?.uniqueCampaigns?.map((item) => ({
        label: item.campaignName,
        value: item._id,
      })) || [],
    },
    {
      label: "Brand Name",
      urlParam: "brandname",
      values:  allFilddters?.uniqueBrands?.map((item) => ({
        label: item.brandName,
        value: item._id,
      })) || [],
    },
    {
      label: "Order Id",
      urlParam: "orderid",
      values:  allFilddters?.uniqueOrderId?.map((item) => ({
        label: item,
        value: item,
      })) || [],
    },
    {
      label: "Upi Id",
      urlParam: "upiid",
      values:  allFilddters?.uniqueUpiId?.map((item) => ({
        label: item,
        value: item,
      })) || [],
    },
    {
      label: "Order Manager",
      urlParam: "ordermanager",
      values: isgetAllOperations?.map((item) => ({
        label: item.name,
        value: item._id,
      })),
    },
  ];  




 
// console.log(row?.deliveryDate)

useEffect(() => {
  // Add Order se wapas aate time refetch trigger karne ke liye
  refetch();
  filterRefech();
}, []);
useEffect(() => {  
  if (user?.role !== "Admin") {  
    if (row && row?.deliveryDate) {  
      const deliveryDate = new Date(row.deliveryDate);  
      const tenDaysLater = new Date(deliveryDate);  
      tenDaysLater.setDate(deliveryDate.getDate() + 10);  
      const currentDate = new Date();  

      if (currentDate > tenDaysLater) {  
        setIsButtonDisabled(true);  
      } else {  
        setIsButtonDisabled(false);  
      }  
    }  
  } else {  
    setIsButtonDisabled(false); // Admins are never disabled  
  }  
}, [user, row]);
  
  const actions = [
    {
      icon: VisibilityIcon,
      onClick: (row) => {
        const selectedData = data?.data?.data?.find((item) => item._id === row.id);
        setSelectedRowData(selectedData);
        setInfluencerDrawerOpen(true);
      },
    },
    {
      icon: HistoryIcon,
      onClick: (row) => {
        const selectedData = data?.data?.data?.find((item) => item._id === row.id);
        setSelectedRowData(selectedData);

        setHistoryDrawerOpen(true);
      },
    },

    {
      icon:
        selectedRowData?.status?.status === "Reimbursement Pending"
          ? ReceiptLongIcon
          : ReceiptLongIcon,
      onClick: (row) => {
        const selectedData = data?.data?.data?.find((item) => item._id === row.id);
        setSelectedRowData(selectedData);
// console.log(selectedData)
        if (selectedData?.status?.status === "Reimbursement Pending") {
          const data = {
            reimbursementValue: selectedData?.reimbursementValue,
            paymentMode: "6602aef19aba5bf62b812323",
            paymentDate: new Date().toISOString().split("T")[0],
            campaign: selectedData?.campaign?._id,
            influencer: selectedData?.influencer?._id,
            order: selectedData?._id,
          };
          postJsonData(
            VisitorEndpoint.ADD_REIMBURSEMENT,
            data,
            () => {},
            (res) => {
              if (res) {
                if (res && res.status === 201) {
                  // handleStatusSubmit();
                  const data = {
                    adminNote: "Reimbursed",

                    status: "65e89f2cdbcc28351f046084",
                  };

                  const endpoint = `${VisitorEndpoint.ADMIN_ORDER_NOTE}/${selectedData?._id}`;
                  putJsonData(
                    endpoint,
                    data,
                    () => {},
                    (res) => {
                      if (res) {
                        if (res && res.status === 200) {
                          refetch();
                        }
                      }
                    },
                    (error) => {
                      toast.error(error.response.data.message);
                    }
                  );
                  toast.success(res.data.message);

                  // navigate("/reimbursement");
                }
              }
            },
            (error) => {
              toast.error(error.response.data.message);
            }
          );
        } else {
          toast.error("This Order Reimbursement Already Paid");
        }
      },
      // disabled: selectedRowData?.status?.status !== "Reimbursement Pending",
      disabled: row?.status?.status !== "Reimbursement Pending",
    },
    {
      icon: EditIcon,
      onClick: (row) => {
        const selectedData = data?.data?.data?.find((item) => item._id === row.id);
        setSelectedRowData(selectedData);
        navigate("/order/edit", { state: { selectedData } });
      },
    },
    {
      icon: PersonAddIcon,
      onClick: (row) => {
        handleOpenManager();
        const selectedData = data?.data?.data?.find((item) => item._id === row.id);
        setEditState(selectedData);
      },
      disabled: row?.orderManager !== null,
    },
    {
      icon: DeleteIcon,
      onClick: (row) => {
        handleOpenRemove();
        const selectedData = data?.data?.data?.find((item) => item._id === row.id);
        setEditState(selectedData);
      },
    },
    {
      icon: RateReviewIcon,
      onClick: (row) => {
        const selectedData = data?.data?.data?.find((item) => item._id === row.id);
        setEditState(selectedData);
        // console.log(selectedData)
        // setrowDatanew(row);
        handleOpenReviewModal();
      },

      disabled:
      !row?.orderScreenshot.length ||
      row?.reviewScreenshot.length ||
      (row?.status?.status === "Placed" ||
        row?.status?.status === "Delivered") || isButtonDisabled ,


    },
    {
      icon: NoteAddIcon,

      onClick: (row) => {
        const selectedData = data?.data?.data?.find((item) => item._id === row.id);
        setEditState(selectedData);
        setrowDatanew(row);
        handleOpenModal();
      },
    },
  ];

  const setRowFunction = (row) => {
    const selectedData = data?.data?.data?.find((item) => item._id === row.id);
    setRow(selectedData);
  };
  const handleRowsPerPageChange = (event) => {
    setPerPageLimit(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };
  const startIndex = (currentPage - 1) * perPageLimit + 1;
  const endIndex = Math.min(startIndex + perPageLimit - 1, totalCount);
  const handleChangePage = (event, newPage) => {
    if (newPage > currentPage - 1 && endIndex < totalCount) {
      setCurrentPage(newPage + 1);
    } else if (newPage < currentPage - 1 && startIndex > 1) {
      setCurrentPage(newPage + 1);
    }
  };

  const setParam = (value) => {
    if (filterParam !== value) {
      dispatch(setOrderFilter(value));
      setCurrentPage(1);
      refetch((prev) => !prev);
    }
  };

  const selectSort = (sortFieldValue, sortOrderValue) => {
    setSortField(sortFieldValue);
    setSortOrder(sortOrderValue);
  };
  // const flattenData = orderDetailsExcel.map((item) => {
  //   const newItem = { ...item };
  //   delete newItem["adminNoteHistory"];

  //   if (item["adminNoteHistory"]) {
  //     item["adminNoteHistory"].forEach((sm, index) => {
  //       newItem[`adminNote ${index + 1}`] = sm.adminNote;
  //       newItem[`status ${index + 1}`] = sm.status;
  //       newItem[`updatedAt ${index + 1}`] = sm.updatedAt;
  //       newItem[`updatedBy ${index + 1}`] = sm.updatedBy;
  //     });
  //   }

  //   return newItem;
  // });
  useEffect(() => {
    if (orderDetailsExcel) {
      const newData = orderDetailsExcel.map((item) => {
        const newItem = { ...item };
        delete newItem["adminNoteHistory"];
        if (item["Order Screenshot"]) {
          if (Array.isArray(item["Order Screenshot"])) {
          item["Order Screenshot"]?.forEach((sm, index) => {
                  newItem[`Order Screenshot ${index + 1}`] = sm;
            
          });  }
      }
      
        if (item["Review Screenshot"]) {
          if (Array.isArray(item["Review Screenshot"])) {
          item["Review Screenshot"]?.forEach((sm, index) => {
                  newItem[`Review Screenshot ${index + 1}`] = sm;
            
          });  }
      }
      
        if (item["Delivered Screenshot"]) {
          if (Array.isArray(item["Delivered Screenshot"])) {
          item["Delivered Screenshot"]?.forEach((sm, index) => {
                  newItem[`Delivered Screenshot ${index + 1}`] = sm;
            
          });  }
      }
      
    
        if (item["adminNoteHistory"]) {
          item["adminNoteHistory"].forEach((sm, index) => {
            newItem[`adminNote ${index + 1}`] = sm.adminNote;
            newItem[`status ${index + 1}`] = sm.status;
            newItem[`updatedAt ${index + 1}`] = sm.updatedAt;
            newItem[`updatedBy ${index + 1}`] = sm.updatedBy;
          });
        }

        return newItem;
      });
      setFlattenData(newData);
      setIsDataReady(true);
    }
  }, [orderDetailsExcel]);

  const handleExport = () => {
    if (isDataReady) {
      const ws = XLSX.utils.json_to_sheet(flattenData);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      XLSX.writeFile(wb, "orderDetailsExcel.xlsx");
    }
  };
  const handleOpenManager = () => {
    setOpenManager(true);
  };
  const handleOpenRemove = () => {
    setEditState(null);
    setOpenRemoveModal(true);
  };

  const handleCloseRemove = () => {
    setEditState(null);
    setOpenRemoveModal(false);
  };
  const handleOpenModal = () => {
    setOpenModalNote(true);
  };

  const handleCloseModal = () => {
    setOpenModalNote(false);
  };
  const handleOpenReviewModal = () => {
    setopenReviewNote(true);
  };
  const handleReviewCloseModal = () => {
    setopenReviewNote(false);
  };
  const handleCloseManager = () => {
    setOpenManager(false);
  };
   const message = "Note - *Reviews must be submitted within 10 days of order delivery; otherwise, the order will not be eligible for reimbursement."
  return (
    <>
      <RemovePopup
        open={openRemoveModal}
        onClose={handleCloseRemove}
        editState={editState}
        endpoint="DELETE_ORDER"
        onSuccess={refetch}
      />
      <OrderViewDetails
        influencerDrawerOpen={influencerDrawerOpen}
        setInfluencerDrawerOpen={setInfluencerDrawerOpen}
        rowData={selectedRowData}
      />
      <NoteHistory
        historyDrawerOpen={historyDrawerOpen}
        setHistoryDrawerOpen={setHistoryDrawerOpen}
        rowData={selectedRowData}
      />
      <AdminNotePopup
        open={openModalNote}
        onClose={handleCloseModal}
        row={rowDatanew}
        statusGroup="Order"
        editState={editState}
        endpoint="ADMIN_ORDER_NOTE"
        onSuccess={refetch}
      />
      <AddReviewPopup
        open={openReviewNote}
        onClose={handleReviewCloseModal}
        row={rowDatanew}
        editState={editState}
        endpoint="ADD_ORDER__REVIEW"
        onSuccess={refetch}
      />
      <AddAmPmPopup
        open={openManager}
        onClose={handleCloseManager}
        row={rowDatanew}
        editState={editState}
        endpoint="ADD_MANAGER"
        onSuccess={refetch}
      />
      <div className="container_fluid_new">
        <div className="container_new">
        <MarqueeText message={message}/>

          <div>
            <ActiveLastBreadcrumb
              links={[
                { name: "Dashboard", href: "/" },
                { name: "Order", href: "/order" },
              ]}
            />
          </div>
          <div className="brands_container">
            <div className="brands_content">
              <p>Order</p>
              <span>{totalCount}&nbsp;Total Order</span>
            </div>

            <div style={{ display: "flex", gap: "10px" }}>
              {user?.role === "Admin" && (
                <>
                  <div>
                    <Tooltip title="Export to Excel" arrow>
                      <div
                        style={{
                          width: "35px",
                          height: "35px",
                          background: "#fff",
                          borderRadius: "50%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                        onClick={handleExport}
                      >
                        <SimCardDownloadIcon
                          sx={{
                            width: "25px",
                            height: "25px",
                            color: "#163e6e",
                          }}
                        />
                      </div>
                    </Tooltip>
                  </div>
                </>
              )}
              <div>
                <CustomButton
                  onClick={() => navigate("/order/add")}
                  label="Add Order"
                />
              </div>
            </div>
          </div>
          {/* <div
            style={{
              marginBottom: "10px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          > */}
          <div
            style={{
              marginBottom: "10px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "20px",
            }}
          >
            <SortPopupComponent
              sortFunction={selectSort}
              sortArray={sortArray}
            />
            {/* <div style={{marginLeft:"10px"}}> */}
            <DeleveryDateRangeFilter
              onApply={({ startDate, endDate }) => {
                // Use the dates to form the parameters
                const deliveryParams = `deliverydate[$lte]=${endDate}&deliverydate[$gte]=${startDate}`;
                setParam(deliveryParams);
              }}
              filterName="Delivery Date"
            />
            <DateRangeFilter
              onApply={({ startDate, endDate }) => {
                // Use the dates to form the parameters
                const params = `orderdate[$lte]=${endDate}&orderdate[$gte]=${startDate}`;
                setParam(params);
              }}
              filterName="Order Date"
            />
          </div>
          {/* </div> */}
          <div
            style={{
              marginBottom: "10px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "20px",
            }}
          >
            <FilterPopupComponent
              filters={filters}
              onChange={setParam}
              isOpen={isOpen}
              filterKey="orderFilter"
              loading={isLoading}
              filterLoading={isLoadingFilter}
            />
          </div>
          {/* </div> */}
          <ShortingTable
            columns={columns}
            loading={isLoading}
            data={realData}
            actions={actions}
            rowFunction={setRowFunction}
            onClickLink={(row) => {
              const selectedData = data?.data?.data?.find(
                (item) => item._id === row.id
              );
              setSelectedRowData(selectedData);
              setInfluencerDrawerOpen(true);
            }}
            onClickTooltip={(row) => {
              const selectedData = data?.data?.data?.find(
                (item) => item._id === row.id
              );
              // setSelectedRowData(selectedData);
            }}
          />

          <TablePagination
            rowsPerPageOptions={[20, 25, 50, 100]}
            component="div"
            count={totalCount}
            rowsPerPage={perPageLimit}
            page={currentPage - 1}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleRowsPerPageChange}
            labelDisplayedRows={({ from, to, count }) =>
              `${startIndex}-${endIndex} of ${count}`
            }
            sx={{
              fontFamily: '"Outfit", sans-serif',
              fontWeight: 600,
              fontSize: "14px",
              color: "#000",
            }}
          />
        </div>
      </div>
    </>
  );
};
export default OrderComponent;
